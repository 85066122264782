/* General Styles */


.blog-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.blog-container h1 {
  margin-top: 20px;
  font-size: 2.5rem;
  text-align: center;
}

.blog-intro {
  text-align: center;
  max-width: 600px;
  margin: 0.75rem 0 3.5rem 0;
  font-size: 1.1rem;
  line-height: 1.6;
}

.blog-cards {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  width: 100%;
  max-width: 1200px;
}

/* BlogCard Styles */
.blog-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.blog-card:hover {
  transform: translateY(-10px);
}

.image-container {
  width: 100%;
  overflow: hidden;
}

.blog-image {
  width: 100%;
  height: auto;
  display: block;
}

.blog-tags {
  padding: 10px 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.tag {
  color: black;
  padding: 10px 20px;
  border-radius: 40px;
  background-color: var(--black-button);
  font-size: 0.9rem;
}
.tag:hover{
  color: var(--primary-color);
}

.blog-description {
  padding: 0 1rem;
  font-size: 25px;
  line-height: 1.6;
}


.moreDetailButton{
  align-self: flex-end;
  font-size: 15px;
  border: none;
  background-color: white;
  color: var(--primary-color);
  margin: 0 1rem 1rem 0;
  cursor: pointer;
}
.uil-arrow-up-right{
  font-size: 17px;

}


/* Responsive Design */
@media (max-width: 768px) {
  .blog-container h1 {
    font-size: 2rem;
  }

  .blog-intro {
    padding: 0 15px;
    font-size: 1rem;
  }

  .blog-cards {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
}

@media (max-width: 480px) {
  .blog-container h1 {
    font-size: 1.5rem;
  }

  .blog-intro {
    font-size: 0.9rem;
  }

  .blog-description {
    font-size: 0.9rem;
  }
}
