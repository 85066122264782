.service__content-title {
    background-image: linear-gradient(to right top, #c0fac2, #98f6dc, #85edf4, #8ee0ff, #aad1ff);  
    height: 550px;
  }
  
  .service-title {
    text-align: center;
  }
  
  .upArrowImage {
    margin: 8rem 0rem 0rem 5rem;
    animation: upDown 2s infinite;
  }
  
  @keyframes upDown {
    0% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
    100% { transform: translateY(0); }
  }
  
  .service-title h1 {
    font-size: 50px;
  }
  
  .service_content-data1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 6rem;
    margin-right: 5rem;
    gap: 5rem;
    margin-bottom: 5rem;
  }
  
  .serviceImage {
    border-radius: 40px 80px 40px 80px;
    margin-left: 5rem;
  }
  
  .service-Imagetext h4 {
    margin-top: 2rem;
    font-size: 30px;
    font-family: var(--font-title);
    line-height: 1;
    margin-left: 3rem;
    font-weight: 500;
    text-align:justify;
    margin-bottom: 1.5rem;
  }
  
  .service-Imagetext p {
    font-size: 18px;
    color: rgb(66, 64, 64);
    font-family: var(--font-content);
    font-weight: 400;
    margin-left: 3rem;

    font-style: normal;
  }
  
  .service_content-data2 {
    display: flex;
    flex-direction: column;
    margin-top: 2rem; /* Add some top margin for spacing */
  }
  
  .service_content-data2 h3 {
    font-size: 24px;
    font-family: var(--font-title);
    font-weight: 500;
    margin-bottom: 1rem;
  }
  
  .service_content-data2 p {
    font-size: 18px;
    color: rgb(66, 64, 64);
    font-family: var(--font-content);
    font-weight: 400;
    font-style: normal;
    margin-bottom: 1rem;
  }
  
  .service__list {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .service__item {
    font-size: 18px;
    color: rgb(66, 64, 64);
    font-family: var(--font-content);
    font-weight: 400;
    margin-bottom: 0.5rem;
  }
  .service__content-data3{
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
  }
  .meetingsFrom-container {
    display: flex;
    margin-top: 2rem;
    justify-content: center; /* Center the image container */
    width: 100%;
  }
  .meetingsFrom{
    
    width: 1000px;
    height: 500px;
    border-radius: 10px;
  }
  .service__data3-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Center items horizontally */
    width: 100%; /* Adjust width as needed */
  }
  
.service__list2{
 
    display: grid;
    width: 80%;
    
    grid-template-columns: repeat(2, 1fr); /* Creates a 2-column grid */
    gap: 2rem; /* Adjust the gap between the items as needed */
    list-style-type: disc;
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding-left: 20px;
}
.service__item2 {
    font-size: 18px;
    color: rgb(66, 64, 64);
    font-family: var(--font-content);
    font-weight: 400;
    margin-bottom: 0.5rem;
 
  }

  .service__contact-block{
    background-color: #e3feef;
    margin-bottom: 2rem;
    font-family: var(--font-title);
    display: flex;
    margin-top: 3rem;
    border-radius: 10px;
    flex-direction: row;
    text-align: center;
    justify-content: space-between;
  }
  .service__contact-data{
    text-align: center; 
 }
 .service__contact-data h1{
    margin-top: 5rem;
    width: 400px;
    font-weight: 500;
    margin-bottom: 3rem;

 }
 .service__contact-data h1{
  margin-left: 10rem;
}
 .linesImage{
  position: relative;
  left: -10px;
  top: -100px;
}
 .getStarted {
    border-radius: 40px;
    background-color: black;
    color: white;
    margin-right: 1rem;
    margin-bottom: 4rem;
    margin-top: 1rem;
 }
 .OurService{
    border-radius: 40px;
 }
  .upperDots{
    margin-right: 6rem;
    margin-left: 2rem;
    animation: upDown 2s infinite;

  }

  @keyframes upDown {
    0% { transform: translateY(0); }
    50% { transform: translateY(-15px); }
    100% { transform: translateY(0); }
  }

  /* For large devices */
@media screen and (max-width: 992px) {
  .service__content-title {
    height: 400px;
  }

  .service-title h1 {
    font-size: 40px;
  }
  .service-title h4{
font-size: 25px;
  }

  .upArrowImage {
    margin: 6rem 0rem 0rem 3rem;
  }

  .service_content-data1 {
    grid-template-columns: 1fr;
    margin-right: 2rem;
    gap: 3rem;
  }

  .serviceImage {
    margin-left: 2rem;
  }

  .service-Imagetext h4, .service-Imagetext p {
    margin-left: 1.5rem;
  }

  .service_content-data2, .service__list2 {
    width: 90%;
  }
  
  
  .service__contact-data h1 {
    font-size: 30px;
    margin: 0;
    margin-top: 3rem;
  }
  .linesImage{
    display: none;
  }


 
}

/* For medium devices */
@media screen and (max-width: 768px) {
  .service__content-title {
    height: 350px;
  }

  .service-title h1 {
    font-size: 35px;
  }

  .upArrowImage {
    margin: 4rem 0rem 0rem 2rem;
  }

  .service_content-data1 {
    margin-top: 4rem;
    margin-right: 1rem;
    gap: 2rem;
  }

  .serviceImage {
    margin-left: 1rem;
  }

  .service-Imagetext h4, .service-Imagetext p {
    margin-left: 1rem;
  }

  .service_content-data2, .service__list2 {
    width: 100%;
  }

  .service__contact-data h1 {
    font-size: 25px;
  }
  .getStarted,
  .OurService{
    padding: 10px 20px;
  }
  .upperDots{
    margin-right: 2rem;
    margin-left: 1rem;
    animation: upDown 2s infinite;

  }
}

/* For small devices */
@media screen and (max-width: 576px) {
  .service__content-title {
    height: 400px;
  }

  .service-title h1 {
    font-size: 30px;
  }

  .upArrowImage {
    margin: 3rem 0rem 0rem 1rem;
  }

  .service_content-data1 {
    margin-top: 3rem;
    margin-right: 0.5rem;
    gap: 1rem;
  }

  .serviceImage {
    margin-left: 0.5rem;
  }

  .service-Imagetext h4, .service-Imagetext p {
    margin-left: 0.5rem;
  }
  .service__contact-block{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
  }

  .service__contact-data h1 {
    font-size:20px;
    margin-top: 2rem;
  }
  .service__contact-data p {
    font-size:13px;
    width: 250px;
    margin-top: 1rem;
    margin-left: 4rem;
  }
  .upperDots{
    margin-left: -3rem;
  }
 
}
@media screen and (max-width: 380px) {
  .service__contact-data h1 {
    font-size:18px;
    margin-top: 2rem;
    margin-left: -1rem;
  }
  .service__contact-data p {
    font-size:11px;
    margin-left: 3rem;
  }
  .upperDots{
    margin-left: -5rem;
  }
}
/* For extra small devices */
@media screen and (max-width: 350px) {
  .service__content-title {
    height: 250px;
  }

  .service-title h1 {
    font-size: 25px;
  }

  .upArrowImage {
    margin: 2rem 0rem 0rem 0.5rem;
  }

  .service_content-data1 {
    margin-top: 2rem;
    margin-right: 0.2rem;
    gap: 0.5rem;
  }

  .serviceImage {
    margin-left: 0.2rem;
  }

  .service-Imagetext h4, .service-Imagetext p {
    margin-left: 0.2rem;
  }

  .service__contact-data h1 {
    font-size: 20px;
    margin-top: 1rem;
  }
}
