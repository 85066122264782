
.home__content{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 2rem;
  justify-content: center;
  margin-top: 2rem;
}
/* .home__content-data{
  margin-top: 4rem;
} */
.home__title{
  font-size: 1.5rem;
  font-weight: 350;
  color: #454444;
}
.home__subtitle{
  font-size: 3rem;
  font-weight: 700;
  color: #000;
}
.home__line{
  width: 39%;
  height: 0.3rem;
  background-color: #378ae9 !important;
  margin-top: 1rem;
  border: none;
}
.home__content-title{
  font-size: 1.2rem;
  font-weight: 350;
  line-height: 1.3;
  font-family: var(--font-content);
  text-align: justify;
}
.home__img{
  width: 100%;
  height: 500px;
  object-fit: cover;
  margin-top: 2rem;
  border-radius: 20px;
  animation: float 3s ease-in-out infinite; /* Add this line for animation */

}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-25px);
  }
  100% {
    transform: translateY(0);
  }
}
.home__button-data{
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}
.home__Contactbutton{
  background-color: var(--primary-color) ;
  color: #fff;
  font-size: 20px;
  padding: 10px 20px 10px 20px;
  border: none;
  border-radius: 10px;
}
.home__Readbutton{
  font-size: 20px;
  padding: 10px 20px 10px 20px;
  border-radius: 10px;

}
.home__Contactbutton:hover{
  color: white;
  background-color: #4cb050;
}
.home__Readbutton:hover{
  background-color: var(--primary-color) ;
color: white;
}

.home__about-title{
font-size: 3rem;
margin-top: 4rem;
margin-bottom: 4rem;
font-weight: 700;
color: #000;
text-align: center;

}

.home__content-about{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-top: 2rem;
}
.aboutImage{
  border-radius: 10px;
  width: 650px;
  height: 440px;
  object-fit: cover;
}
.home__aboutbutton{
  background-color: var(--black-button);
  border-radius:30px;
  margin-top: 1rem;
  font-size: 20px;
  cursor: none;
  padding: 10px 20px 10px 20px;
  border: none;
  margin-bottom: 1rem;
  color: rgb(66, 64, 64);
}
.home__aboutbutton:hover{
  background-color: rgba(0, 0, 0, 0.2);
}
.about__title{
  font-size: 2.5rem;
  font-weight: 700;
  color: #000;
}
.about__subtitle{
  font-size: 1.2rem;
  font-family: var(--font-content);
  font-weight: 350;
}
.home-dataImg{
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 2rem;
}
.aboutdataImage{
  border-radius: 15px;
  width: 250px;
  height: 170px;
  object-fit: cover;
}
.home__features {
margin-top: 1rem;
background-color: #fff;
padding: 3rem 0;
}

.feature-list {
list-style: none;
padding: 0;
justify-content: center;
align-items: center;
margin: 0;
display: flex;
flex-wrap: wrap;
gap: 2rem;
}

.feature-item {
display: flex;
align-items: flex-start;
width: 40%;
margin-bottom: 2rem;
}

.feature-dot {
width: 12px;
height: 12px;
padding: 8px;
background-color: #ff5722;
margin-top: 5px;
border-radius: 50%;
margin-right: 1rem;
flex-shrink: 0;
animation: blink 1s infinite; /* Apply the blinking animation */

}

@keyframes blink {
0% {
  background-color: #ff5722; /* On (orange) */
}
50% {
  background-color: #ffccbc; /* Off (light orange) */
}
100% {
  background-color: #ff5722; /* On (orange) */
}
}

.feature-content {
max-width: calc(100% - 2rem);

}

.feature-title {
font-size: 1.2rem;
font-weight: bold;
margin-bottom: 0.5rem;
}

.feature p {
font-size: 1rem;
color: #666;
line-height: 1.5;
}
.home__grom{
display: flex;
flex-direction: column;
flex-wrap: wrap;
align-items: center;
justify-content: center;
}

.home__grom-title{
font-size: 2rem;
margin-top: 0.50rem;
font-weight: 600;
color: #000;
}
.home__grom-subtitle{
font-size: 1.2rem;
text-align: center;
width: 800px;
margin-top: 0.50rem;
font-weight: 500;
color: #1c1b1b;
}
.home__grom-content{
display: grid;
grid-template-columns: repeat(5, 1fr);
gap: 1rem;
margin-top: 2rem;
}


.home__grom-data h3{
font-size: 1.3rem;
margin-top: 0.50rem;
font-weight: 600;
}
.home__grom-data h4{
font-size: 1.3rem;
margin-top: 0.50rem;
cursor: pointer;
font-weight: 600;
color: #378ae9;
}
.home__grom-data h4:hover{
  text-decoration: underline;
}
.gromImage{
border-radius: 10px;
width: 300px;
height: 200px;
object-fit: cover;
}
.uil-arrow-right {
transition: transform 0.3s ease; /* Add a transition effect on the transform property */
cursor: pointer;
}

.uil-arrow-right:hover{
transform: translateX(10px); /* Move the arrow 10px to the right on hover */
}

.home__service-title {
font-size: 3rem;
margin-top: 5rem;
margin-bottom: 4rem;
text-align: center;
font-family: var(--font-title);
color: var(--black);
line-height: 1.1;
}

.home__service-content {
display: flex;
flex-wrap: wrap;
justify-content: center;
gap: 2rem;
}

.card {
display: grid;
place-items: center;
width: 90vw;
max-width: 21.875rem;
height: 24.125rem;
overflow: hidden;
border-radius: 0.625rem;
box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
}

.card > * {
grid-column: 1 / 2;
grid-row: 1 / 2;
}

.card__background {
object-fit: cover;
width: 100%;
height: 100%;
}

.card__content {
--flow-space: 0.9375rem;
display: flex;
flex-direction: column;
justify-content: space-between;
align-self: flex-end;
height: 55%;
padding: 12% 1.25rem 1.875rem;
/* background: linear-gradient(
  180deg,
  hsla(0, 0%, 0%, 0) 0%,
  hsla(0, 0%, 0%, 0.3) 10%,
  hsl(0, 0%, 0%) 100%
); */
}

.card__content--container {
--flow-space: 1.25rem;
background-color: var(--overlay-background);
border-radius: 20px;
padding: 1rem 1rem;
}


.card__title {
position: relative;
padding: 0.50rem;
font-size: 25px;
margin-bottom: 1.5rem;
width: 270px;
width: -moz-fit-content; /* Prefijo necesario para Firefox  */
}

.card__title::after {
content: "";
position: absolute;
height: 0.3125rem;
width: calc(100% + 1.25rem);
bottom: calc((1.25rem - 0.5rem) * -1);
left: -1.25rem;
background-color: var(--brand-color);
}

.card__description {
font-family: var(--font-text);
font-size: 1rem;
line-height: 1.5;
color: var(--white);
}

.card__button {
padding: 0.75em 0.6rem;
width: fit-content;
width: -moz-fit-content; 
font-variant: small-caps;
font-weight: bold;
border-radius: 0.45em;
border: none;
background-color: var(--brand-color);
font-family: var(--font-title);
font-size: 1.125rem;
color: var(--black);
}

.card__button:focus {
/* outline: 2px solid black; */
outline-offset: -5px;
}
.card__button:hover{
  text-decoration: underline;
  animation:forwards 2s infinite;
}

@keyframes upDown {
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
}

@media (any-hover: hover) and (any-pointer: fine) {
.card__content {
  transform: translateY(62%);
  transition: transform 500ms ease-out;
  transition-delay: 500ms;
}

.card__title::after {
  opacity: 0;
  transform: scaleX(0);
  transition: opacity 1000ms ease-in, transform 500ms ease-out;
  transition-delay: 500ms;
  transform-origin: right;
}

.card__background {
  transition: transform 500ms ease-in;
}

.card__content--container > :not(.card__title),
.card__button {
  opacity: 0;
  transition: transform 500ms ease-out, opacity 500ms ease-out;
}

.card:hover,
.card:focus-within {
  transform: scale(1.05);
  transition: transform 500ms ease-in;
}

.card:hover .card__content,
.card:focus-within .card__content {
  transform: translateY(0);
  transition: transform 500ms ease-in;
}

.card:focus-within .card__content {
  transition-duration: 0ms;
}

.card:hover .card__background,
.card:focus-within .card__background {
  transform: scale(1.3);
}

.card:hover .card__content--container > :not(.card__title),
.card:hover .card__button,
.card:focus-within .card__content--container > :not(.card__title),
.card:focus-within .card__button {
  opacity: 1;
  transition: opacity 500ms ease-in;
  transition-delay: 200ms;
}

.card:hover .card__title::after,
.card:focus-within .card__title::after {
  opacity: 1;
  transform: scaleX(1);
  transform-origin: left;
  transition: opacity 500ms ease-in, transform 500ms ease-in;
  transition-delay: 500ms;
}
}


.Home__hireBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-top: 4rem;
  margin-bottom: 3rem;
  background-color: #edeff7; /* Update as needed */
  padding: 40px 0; /* Adjust padding as necessary */
}

.home__hire-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-image: url(../assets/Shape\ 1.svg);
  background-position: -200px;
  background-repeat: no-repeat;
  background-size: 900px 650px;
  text-align: center;
}

.home__hire-content h1 {
  width: 500px;
  font-family: var(--font-title);
  margin-top: 3rem;
  font-size: 34px; /* Adjust font size as necessary */
  color: #2a2828; /* Update text color as needed */
  margin-bottom: 20px; /* Space between text and button */
}

.shapearrowImage {
  transform: rotate(185deg);
  margin-right: 5rem;
  position: relative;
  top: -50px;
  margin-left: 3rem;
}

.button-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dots-container {
  gap: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px; /* Space between dots and button */
}

.dot {
  background: linear-gradient(45deg, #f5903d, #fb8f37);
  width: 8px;
  border-radius: 5px;
  height: 40px; /* Adjust height as needed */
  margin: 5px 0; /* Space between dots */
}

.hire-button {
  background: linear-gradient(45deg, #ff7e5f, #feb47b);
  color: #fff; /* Button text color */
  padding: 10px 60px; /* Adjust button padding */
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: none;
  border-radius: 5px; /* Adjust border radius */
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 16px; /* Adjust font size */
}

.hire-button:hover {
  background-color: #e68900; /* Adjust hover background color */
}

.upperCentre {
  position: relative;
  top: -10px;
}

.bottomCentre {
  position: relative;
  top: 10px;
}

.upperLeft,
.bottomRight {
  transform: rotate(-40deg); /* Change the angle here */
}

.upperRight,
.bottomLeft {
  transform: rotate(45deg); /* Change the angle here */
}



.Home__whyChooseBlock {
  display: flex;
  flex-direction: row;  
  margin-top: 8rem;
  border: 2px solid rgb(39, 38, 38);
}

.home__chooseBlock1-content {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
}

.chooseblock1-data1, .chooseblock1-data2, .chooseblock3-subpart1, .chooseblock3-subpart2 {
  padding: 1rem;
  font-size: 1.2rem;
}

.chooseblock1-data1,
.chooseblock3-subpart1 {
  background-color: #315450;
  color: white;
}

.chooseblock1-data2 {
  background-color: #f3f3f2;
}

.home__chooseBlock2-content {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home__choosegirlImage {
  width: 100%;
  height: 100%;
}

.home__chooseBlock3-content {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
}


.chooseblock3-data1 h1{
  text-align: end;
  text-transform: uppercase;
font-size: 4.5rem;
margin: 2rem 3rem 0rem 5rem;
}
.chooseblock3-data1 h3{
font-size: 1.2rem;
font-weight: 400;
text-align: end;
color: #666;
margin-left: 8rem;
margin-right: 3rem;
}
.fontWeight{
  /* text-align: end; */
  font-weight: 200;
  }
.chooseblock3-data2 {
  display: flex;
}

.chooseblock3-subpart1 {
  flex: 1;
  padding: 1rem;
}

.chooseblock3-subpart2 {
  background-color: #000000;
  flex: 1;
  color: white;
  padding: 1rem;
}

.chooseIcon {
  width: 40px;
  margin-bottom: 1rem;
  margin-top: 1rem;
}



/* Responsive adjustments */
/* Responsive adjustments */

@media screen and (max-width: 992px) {

  .home__content-about{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }
  .home__grom{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    }
    .home__grom-content{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      justify-content: center;
      gap: 1rem;
      margin-top: 2rem;
      }
      .home__hire-content {
        background-position: center; /* Adjust background position for medium screens */
      }
      .hire-button{
        width: max-content;
        padding: 8px 40px; /* Adjust button padding */
      }
      .shapearrowImage {
        margin-right: 1rem;
        margin-left: 0rem;
      }
      .Home__whyChooseBlock {
        flex-direction: column; /* Stack items vertically on medium screens */
        margin-top: 6rem; /* Adjust top margin */
      }
    
      .home__chooseBlock2-content {
        width: 100%; /* Full width for medium screens */
      }
    
      .chooseblock3-data1 h1 {
        font-size: 3.5rem; /* Adjust heading font size for medium screens */
        margin: 2rem; /* Adjust margin */
      }
    
      .chooseblock3-data1 h3 {
        font-size: 1rem; /* Adjust subheading font size for medium screens */
        margin-left: 3rem; /* Adjust margin */
        margin-right: 3rem; /* Adjust margin */
      }

}

/* For medium devices */
@media screen and (max-width: 768px) {
  .feature-item {
    width: 100%;
  }
  .home__subtitle{
    font-size: 2rem;
  }

  .home__content {
    grid-template-columns: 1fr
  }
  .home__img {
    order: -1; /* Move image to the top */
    margin-bottom: 1rem; /* Add space below the image */
  }
  .home__grom-content{
  
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 2rem;
    }
    .gromImage{
      width: 400px;
    }
  
    .home__hire-content {
      flex-direction: row; /* Adjust flex direction for smaller screens */
    }
  
    .home__hire-content h1 {
      width: 60%; /* Adjust width of heading for smaller screens */
      font-size: 20px; /* Adjust font size for smaller screens */
    }
    .hire-button{
      padding: 8px 30px; /* Adjust button padding */
    }
  
    .shapearrowImage {
      margin: 0; /* Remove margin for smaller screens or adjust as needed */
    }
    .chooseblock3-data1 h1 {
      font-size: 3rem; /* Adjust heading font size for smaller screens */
    }
  
    .chooseblock3-data1 h3 {
      font-size: 1rem; /* Adjust subheading font size for smaller screens */
      margin-left: 2rem; /* Adjust margin */
      margin-right: 2rem; /* Adjust margin */
    }
  
}

@media screen and (max-width: 576px) {
  .aboutdataImage{
    width: 200px;
  }
  .home-dataImg{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 2rem;
  }
  
  .home__hire-content {
    background-size: contain; /* Adjust background size for smaller screens */
  }

  .hire-button {
    padding: 10px 40px; /* Adjust button padding for smaller screens */
  }
  .home__hire-content h1 {
    width: 40%; /* Adjust width of heading for smaller screens */
    font-size: 15px; /* Adjust font size for smaller screens */
  }
  .hire-button{
    width: max-content;
    padding: 8px 20px; /* Adjust button padding */
  }
  .chooseblock3-data1 h1 {
    font-size: 2.5rem; /* Adjust heading font size for small screens */
    margin: 1rem; /* Adjust margin */
  }
  .shapearrowImage{
    width: 60px;
  }
  .chooseblock3-data1 h3 {
    font-size: 0.9rem; /* Adjust subheading font size for small screens */
    margin-left: 1rem; /* Adjust margin */
    margin-right: 1rem; /* Adjust margin */
  }

  .chooseblock3-subpart1, .chooseblock3-subpart2 {
    padding: 0.5rem; /* Adjust padding for small screens */
  }
}

/* For small devices */
@media screen and (max-width: 350px) {

  .home__hire-content h1 {
    font-size: 15px; /* Further adjust font size for very small screens */
  }
  .shapearrowImage{
    display: none;
  }
  .hire-button{
    margin-left: 1rem;
    padding: 6px 20px; /* Adjust button padding */
  }
  .chooseblock3-data1 h1 {
    font-size: 2rem; /* Further adjust heading font size for very small screens */
    margin: 0.5rem; /* Further adjust margin */
  }

  .chooseblock3-data1 h3 {
    font-size: 0.8rem; /* Further adjust subheading font size for very small screens */
    margin-left: 0.5rem; /* Further adjust margin */
    margin-right: 0.5rem; /* Further adjust margin */
  }

  .chooseblock3-subpart1, .chooseblock3-subpart2 {
    padding: 0.3rem; /* Further adjust padding for very small screens */
  }

  
}

