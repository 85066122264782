
@import url('https://fonts.googleapis.com/css2?family=Livvic:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,900&display=swap');
@import url('https://db.onlinewebfonts.com/c/4025a96f8849d7913d59f9ca28bf59a4?family=Sztos+Test+Medium+Condensed');

@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap');
:root{

    /* Font diffrent diffent size */
  --big-font-size: 3.5rem;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;
  --tiny-font-size: 0.625rem;
  --black: hsl(0, 0%, 0%);
  --white: hsl(0, 0%, 4%);
  --overlay-background: rgba(255, 255, 255, 0.8); /* Transparent white */
  --black-button: rgb(222, 222, 222);
  --primary-color:#378ae9;
  --secondary-color:#4cb050;
  --font-title: "Montserrat", sans-serif;
  --font-subtitle:"Archivo", sans-serif;
  --font-style-title:"Sztos Test Medium Condensed";
    --font-text: "Lato", sans-serif;
  --font-content:"Livvic", sans-serif;
  --body-font: "Poppins", sans-serif;


   /*========== Font weight ==========*/
   --font-normal: 400;
   --font-medium: 500;
   --font-semi-bold: 600;
   
/*========== z index ==========*/
--z-tooltip: 10;
--z-fixed: 100;
--z-modal: 1000;

}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body,
button,
input,
textarea {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

body {
  background-color: var(--body-color);
  color: var(--text-color);
}

h1,
h2,
h3 {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}


img {
  max-width: 100%;
  height: auto;
}

/*=============== REUSABLE CSS CLASSES ===============*/
.section {
  padding: 6rem 0 2rem;
}

.section__title {
  font-size: var(--h1-font-size);
  color: var(--title-color);
}

.section__subtitle {
  display: block;
  font-size: var(--small-font-size);
  margin-bottom: 4rem;
}

.section__title,
.section__subtitle {
  text-align: center;
}

/*=============== LAYOUT ===============*/
.container {
  margin-left: auto;
  margin-right: auto;
}

.grid {
  display: grid;
  gap: 1.5rem;
}
.icon{
  background-color: none;
  background-color: transparent;


}


/*=============== BUTTONS ===============*/
.button {
  display: inline-block;
  background-color: var(--title-color);
  color: var(--container-color);
  

  padding: 1.25rem 2rem;
  border-radius: 1rem;
  font-weight: var(--font-medium);
}

.button:hover {
  color: black;
  background-color: white;
}

.button__icon {
  margin-left: var(--mb-0-5);
}

.button--flex {
  display: inline-flex;
  align-items: center;
  
  }@media screen and (max-width: 992px) {
  
    }
    
    /* For medium devices */
    @media screen and (max-width: 768px) {
    
    }
    
    @media screen and (max-width: 576px) {
    }
    
    /* For small devices */
    @media screen and (max-width: 350px) {
      
    }
    