.aboutPage__content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    max-width: 1200px;
  }
.aboutPage__content p{
    font-size: 1rem;
    color: #3d3d3d;
    line-height: 1.2;
    margin: 11.5rem 0rem 20px 2rem;
    font-family: var(--font-content);
}  
  .aboutImage {
    flex: 1;
    width: 600px;
    height: 450px;
    margin-right: 20px;
    border-radius: 10px;
  }
  
  .aboutText {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .aboutText h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
  }
  .aboutPage__title{
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    top: 150px;
    left: -300px;
    gap: 9rem;
    justify-items: center;


  }
  .aboutPage__title h1{
    font-size: 6rem;
    font-family: var(--font-subtitle);
    text-transform: uppercase;
    z-index: 2;
  }

  .FireImage{
    width: 100px;
    mix-blend-mode: multiply;
    animation: rotate 60s linear infinite; /* Added rotation animation */
    z-index: 2;

  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .aboutPage__content-process{
    margin-top: 4rem;

  }
  .workProcessButton{
    width: fit-content;
    padding: 6px 20px 6px 20px;
    border-radius: 45px;
    margin-bottom: 1rem;
    margin-top: 4rem;
    border: none;
    font-size: 20px;
    background-color: var(--black-button);
  }
  .workProcessButton:hover{
    background-color: var(--black-button);
  }
  .aboutPage__process-data{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .process__title h2{
    font-size: 4rem;
    font-family: var(--font-title);
    margin-bottom: 2rem;

  }
  .process__title p{
    font-size: 15px;
    font-family: var(--font-content);
  }
.process__diagram{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;

}
.righttoleft,
.lefttoright{
  width: 50px;
}

.diagram__icon{
  margin-right: 8px;
}
.AnalysisButton{
  position: relative;
  padding: 15px 35px 15px 35px ;
  border-radius: 50px;
  pointer-events: none;

  border: none;
  background-color: rgb(249, 245, 250);
  border-bottom: 1px  solid #f091e5;
  font-size: 25px;
  left: 180px;
}
.righttoleft{
  position: relative;
  left: 150px;
}
.proofButton{
  border-radius: 50px;
  padding: 15px 35px 15px 35px ;
  position: relative;
  top: -40px;
  pointer-events: none;

  border: none;
  background-color: rgb(249, 245, 250);
  border-bottom: 1px  solid #f091e5;
  left: -30px;
  font-size: 25px;

}
.lefttoright{
width: 130px;
position: relative;
top: -50px;
left: -20px;
}
.ExectionButton{
  border-radius: 50px;
  font-size: 25px;
  position: relative;
  top: -100px;
  border: none;
  pointer-events: none;

  background-color: rgb(249, 245, 250);
  border-bottom: 1px  solid #f091e5;
  padding: 15px 35px 15px 35px ;
  left: 180px;


}
.Exectionrighttoleft{
  position: relative;
  top: -100px;
  left: 200px;
  
}
.LaunchButton{
  position: relative;
  border-radius: 50px;
  padding: 15px 35px 15px 35px ;
  top: -140px;
  pointer-events: none;

  border: none;
  background-color: rgb(249, 245, 250);
  border-bottom: 1px  solid #f091e5;
  font-size: 25px;
}

@media screen and (max-width: 1400px) {
  .aboutPage__title {
    max-width: 1100px;
  }
  .aboutPage__title{
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    top: 150px;
    left: -200px;
    gap: 7rem;
    justify-items: center;


  }
}
@media screen and (max-width: 1200px) {
  .aboutPage__title {
    max-width: 1100px;
  }
  .aboutPage__title{
    top: 180px;
    left: -200px;
    text-align: center;
    gap: 7rem;


  }
}

/* Responsive Queries */
@media screen and (max-width: 992px) {
  .aboutPage__title {
    max-width: 1000px;
  }
  .aboutPage__title{
    left: 0;
    top: 0;
    grid-template-columns: repeat(1,1fr);
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .FireImage{
    display: none;
  }

  .aboutPage__title h1 {
      font-size: 4rem;
      margin: 3rem 0rem;
      text-align: center;
  }
  .aboutPage__content {
      flex-direction: column;
      align-items: center;
  }
  .aboutImage {
      width: 100%;
      margin-right: 0;
  }
  .aboutText h2 {
      font-size: 2rem;
      text-align: center;
  }
  .aboutPage__content p{
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .aboutPage__process-data{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
 
}

@media screen and (max-width: 768px) {
  .aboutPage__content-process{
    margin-top: 0;
  }
  .process__title h2 {
      font-size: 3rem;
      text-align: center;
  }
  .process__title p {
      font-size: 14px;
      text-align: center;
  }
  .aboutPage__process-data {
      grid-template-columns: 1fr;
      gap: 2rem;
  }
  .process__diagram{
    position: relative;
    left: -100px;
  }

  
}

@media screen and (max-width: 576px) {
  .aboutPage__title h1 {
      font-size: 3rem;
  }
  .process__title h2 {
      font-size: 2rem;
  }
  .process__title p {
      font-size: 12px;
  }
  .AnalysisButton,
  .proofButton,
  .ExectionButton,
  .LaunchButton {
      font-size: 20px;
      padding: 10px 20px;
  }
  .process__diagram{
    margin: 0 3rem;
  }
}
@media screen  and (max-width: 470px){
  .AnalysisButton{
    left: 140px;
  }
  .righttoleft{
    position: relative;
    left: 150px;
  }
  .proofButton{
    left: 15px;

  }
  .ExectionButton{
    left: 160px;
  }

  .Exectionrighttoleft{
    left: 180px;
  }
  .LaunchButton{
    left: 30px;
  }
}

@media screen and (max-width: 350px) {
  .aboutPage__title h1 {
      font-size: 2rem;
  }
  .process__title h2 {
      font-size: 1.5rem;
  }
  .process__title p {
      font-size: 10px;
  }
  .AnalysisButton,
  .proofButton,
  .ExectionButton,
  .LaunchButton{
    font-size: 15px;
  }
  .AnalysisButton{
    left: 150px;
  }
  .righttoleft{
    position: relative;
    left: 150px;
  }
  .proofButton{
    left: 30px;

  }
.lefttoright{
  width: 110px;
  left: 20px;
}
.Exectionrighttoleft{
  left: 180px;
}
.LaunchButton{
  left: 40px;
}
}