.header {
  width: 100%;
  top: 0;
  left: 0;
  position: sticky;
  z-index: var(--z-fixed);
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.nav {
  height: 4.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav__logo {
  margin-left: 5rem;
  font-size: 1.1rem;
  color: black;
  font-weight: var(--font-medium);
}

.brand__img {
  width: 150px;
}

.nav__list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.nav__link {
  display: flex;
  flex-direction: column;
  font-size: 1.3rem;
  color: var(--title-color);
  font-weight: var(--font-medium);
  transition: .3s;
}

.nav__link.nav__consult {
  margin-top: 7px;
  background-color: var(--primary-color);
  color: white;
  margin-left: 5rem;
  padding: 10px 20px;
  border-radius: 5px;
}

.nav__link.nav__consult:hover {
  color: white;
  background-color: var(--secondary-color);
}

.nav__icon,
.nav__close,
.nav__toggle {
  display: none;
}

.active-link,
.nav__link:hover {
  color: var(--first-color);
}

.nav__profile {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  right: 1rem;
}

.nav__search {
  padding: 0.5rem;
  border-radius: 20px;
  border: 1px solid var(--title-color);
}

.nav__profileImg {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 2rem;
}

@media screen and (max-width: 992px) {
  .nav__logo {
    margin-left: 0rem;
  }
  .nav{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .nav__link.nav__consult {
    margin-left: 0rem;
  }


  /* .nav__profile {
    display: none;
  } */
}

@media screen and (max-width: 768px) {
  .header {
    top: 0;
    bottom: initial;
  }
  .nav__link.nav__consult {
    margin-left: 0;
  }


  .nav {
    height: var(--header-height);
    flex-direction: row;
    justify-content: space-between;
  }

  .nav__menu {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 2rem 1.5rem;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    transition: .3s;
  }

  .show-menu {
    left: 0;
  }

  .nav__list {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }

  .nav__icon {
    font-size: 1.2rem;
  }

  .nav__close {
    position: absolute;
    right: 1.3rem;
    top: 1.5rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--title-color);
  }

  .nav__close:hover {
    color: var(--title-color-dark);
  }

  .nav__toggle {
    font-size: 1.1rem;
    cursor: pointer;
  }

  .nav__icon,
  .nav__toggle,
  .nav__close {
    display: block;
  }

  .nav__profile {
    display: flex;
  }

}

@media screen and (max-width: 350px) {
  .nav__menu {
    padding: 2rem 0.25rem;
  }

  .nav__list {
    column-gap: 0;
  }
}

/* Styles for the Consult Now button */



@media screen and (max-width: 1200px) {
  .nav__link {
    font-size: 1rem;
  }
}
