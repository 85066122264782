.careers_page {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 3rem;
}

.careers_content {
    height: auto;
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    margin: 0 auto;
}

.careers_content p {
    font-family: var(--font-content);
}

.careers_img {
    height: auto;
    width: 100%;
    max-width: 700px;
    margin-top: 20px;
    border-radius: 100px 40px 100px 40px;
    opacity: 1;
}

.careers_button {
    width: max-content;
    border-radius: 50px;
    border: none;
    font-weight: 600;
    background-color: var(--primary-color);
    color: white;
}

.careers_button:hover {
    background-color: var(--secondary-color);
    color: white;
}

.culture_card {
    height: inherit;
    width: 100%;
    max-width: 700px;
   
    display: flex;
    flex-direction: column;
}

.culture_content {
    height: auto;
    width: 100%;
    max-width: 500px;
    margin-bottom: 10px;
    margin: 0 auto;
}
.left_card{
    margin-top: -20px;
}

.left_card,
.right_card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 2rem;
    height: auto;
    width: 100%;
}

.career_card {
    height: auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: 10px;
    border-radius: 15px;
    background-color: rgb(237, 233, 233);
}

.culture_content {
    max-height: calc(100% - 100px);
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 40px;
    margin: 100px auto 0;
}

.card_Img {
    margin-left: 5px;
    margin-top: 10px;
    height: 40px;
    width: 40px;
}

.card_content {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-top: 10px;
    gap: 10px;
}

.culture_content p {
    font-family: var(--font-content);
}

.culture_content h1 {
    margin-top: 3rem;
}

.career_btn {
    height: 70px;
    width: 300px;
    border-radius: 20px;
    font-size: 40px;
}

@keyframes colorChange {
    0% {
        background-position: 10% 20%;
    }
    100% {
        background-position: 0% 100%;
    }
}

.career_button {
    background: var(--primary-color);
    background-size: 400% 400%;
    animation: colorChange 4s linear infinite;
    border: none;
    color: white;
    padding: 20px 40px;
    cursor: pointer;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
    width: max-content;
    margin-bottom: 2rem;
}
.careers_button a{
    color: white;
}
.career_button:hover {
    transform: scale(1.1);
}

/* Media Queries */
@media screen and (max-width: 768px) {
    .careers_page {
        grid-template-columns: 1fr;
       
        width: 100%;
    }
    .careers_img {
        display: none;
       
    }
    .careers_content, .careers_img, .culture_card, .culture_content {
        margin: 0;
        padding: 0;
    }
}

@media (min-width: 768px) {
    .careers_page {
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
    
    .careers_content, .careers_img, .culture_card, .culture_content {
        margin: 0;
    }

    .careers_img {
        height: 100%;
        width: 90%;
    }
    .culture_card {
        flex-direction: row;
    }

    .left_card, .right_card {
        width: 50%;
    }
}
@media (min-width: 900px) {
    .careers_img {
        height: 100%;
        width: 120%;
    }
}

@media (min-width: 1024px) {
    .careers_content {
        height: 90vh;
    }

    .careers_img {
        height: 500px;
        width: 90%;
    }

    .culture_card {
        height: 100vh;
    }

    .culture_content {
        margin: 0 4rem;
        height: fit-content;
    }
}